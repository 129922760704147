



















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DeskBanner',
  emits: ['change-play'],
  setup(props, { emit }) {
    const openVideo = () => {
      emit('change-play')
    }
    return { openVideo }
  }
})
